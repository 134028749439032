let carouselItem = document.getElementsByClassName("carousel-item");

function throttle(fn, wait) {
  var time = Date.now();
  return function (e) {
    if (
      (e.deltaY > 0 &&
        !carouselItem[carouselItem.length - 1].classList.contains("active")) ||
      (e.deltaY < 0 && !carouselItem[0].classList.contains("active"))
    ) {
      e.preventDefault();
    }
    if (time + wait - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
}
let myCarousel = document.getElementById("myCarousel");
function scrollDown() {
  // this is where you tell it to go to the next slide
  $(".carousel").carousel("next");
}
function scrollUp() {
  // this is where you tell it to go to the prev slide
  $(".carousel").carousel("prev");
}

function callback(e) {
  e.deltaY > 0 ? scrollDown() : scrollUp();
}
myCarousel.addEventListener("wheel", throttle(callback, 2100));
